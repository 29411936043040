import React, { Fragment, useContext, useState } from "react";
import PropTypes from "prop-types";

import AuthContext from "../../context/auth/authContext";
import RankContext from "../../context/rank/rankContext";

import AgainStarsComments from "../common/AgainStarsComments";
import NameLink from "../common/NameLink";
import DisplayImage from "../common/DisplayImage";

import { buttonPrefix, userMatches } from "./rank_resuse";
import { buttonStyle, getButton } from "./rankButtons";
import { getSepValueIfExists } from "../../utils/functionsCommon";

const RankItemDetail = ({
  rank,
  keyId,
  theMode,
  mode,
  onChange,
  onSaveAdd,
  onDelete,
}) => {
  const authContext = useContext(AuthContext);
  const { user, isAuthenticated } = authContext;

  const rankContext = useContext(RankContext);
  const {
    current_rank,
    rank_selected,
    setCurrentRank,
    clearCurrentRank,
    current_category,
  } = rankContext;

  const showControl = (fieldName, rank) => {
    let options = {};
    if (mode === theMode.edit && rank.attributes[fieldName] !== undefined)
      options.value = rank?.attributes[fieldName];

    return (
      <input
        {...options}
        key={fieldName}
        type="text"
        className="grid-input-acct"
        name={"attributes." + fieldName}
        placeholder={fieldName.toLowerCase()}
        onChange={onChange}
      />
    );
  };

  // shows the attributes for the rank record
  //    i.e. for restaurant, name, location, etc.
  //    i.e. for drinks, company / distiller, year etc.
  const showAttributes = (rank) => {
    let attrs;
    if (current_category?.attributes) {
      attrs = current_category?.attributes;
    } else if (rank?.attributes) {
      attrs = Object.keys(rank?.attributes);
    } else {
      attrs = [];
    }

    return attrs.map((field, idx) => {
      // if (!rank || !rank?.attributes?.[field]) {
      //   console.log("no attributes");
      // }
      if (mode !== theMode.show) return showControl(field, rank);
      if (!field || !rank?.attributes?.[field]) return "";
      return getSepValueIfExists(rank?.attributes[field], "|", idx);
    });
  };

  const buttonPre = buttonPrefix(rank, current_category);
  return (
    <div className="col" key={`col-${keyId}`}>
      <div className="card" key={`card-${keyId}`}>
        <div className="card-body" key={`card-title-body-${keyId}`}>
          <h5
            className="card-title  text-center"
            key={`card-title-title-${keyId}`}
          >
            <NameLink
              rank={rank}
              current_rank={current_rank}
              theMode={theMode}
              mode={mode}
              onChange={onChange}
              clearCurrentRank={clearCurrentRank}
              setCurrentRank={setCurrentRank}
            />
          </h5>
        </div>
        <DisplayImage
          rank={rank}
          theMode={theMode}
          mode={mode}
          loggedIn={isAuthenticated}
          onChange={onChange}
        />

        <div className="card-body" key={`card-body-${keyId}`}>
          <div className="card-text" key={`card-text-${keyId}`}>
            <div className="row" key={`card-content-r2-${keyId}`}>
              <div
                className="col text-center"
                key={`card-content-r2c1-${keyId}`}
              >
                {showAttributes(rank)}
              </div>
            </div>
            {mode === theMode.edit ? (
              ""
            ) : (
              <AgainStarsComments
                record={rank}
                theMode={theMode}
                mode={mode}
                buttonPre={buttonPre}
                onChange={onChange}
                keyId={"votes-comments" + keyId}
                showVotes={true}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

RankItemDetail.propTypes = {
  rank: PropTypes.object,
  keyId: PropTypes.string,
  theMode: PropTypes.object,
  mode: PropTypes.number,
  onChange: PropTypes.func,
  onSaveAdd: PropTypes.func,
  onDelete: PropTypes.func,
};

export default RankItemDetail;
