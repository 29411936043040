import React, { Fragment, useContext, useEffect } from "react";
import RankContext from "../../context/rank/rankContext";
import { isArrayEmpty } from "../rank/rank_resuse";

const CategoryMap = () => {
  const rankContext = useContext(RankContext);
  const { categorys, current_category, setCurrentCategory } = rankContext;

  useEffect(() => {
    // eslint-disable-next-line
  }, [current_category]);

  const showCatMap = (cat) => {
    let currentCat = current_category?._id === cat?._id;
    let active = currentCat ? "active" : "";
    return (
      <button
        key={"cat_map_" + cat._id}
        className={`btn btn-sm btn-outline-success me-1 ${active}`}
        onClick={() => setCurrentCategory(cat)}
      >
        {cat.name + "  "}
      </button>
    );
  };

  return (
    <div>
      {!isArrayEmpty(categorys) ? categorys.map((cat) => showCatMap(cat)) : " "}
    </div>
  );
};

export default CategoryMap;
