import React from "react";
import PropTypes from "prop-types";

const CardTemplate = ({
  imageSection,
  titleSection,
  contentSection,
  keyId,
}) => {
  return (
    <div className="col" key={`col-${keyId}`}>
      <div className="card" key={`card-${keyId}`}>
        <div className="card-body" key={`card-title-body-${keyId}`}>
          <h5
            className="card-title  text-center"
            key={`card-title-title-${keyId}`}
          >
            {titleSection()}
          </h5>
        </div>
        {imageSection()}

        <div className="card-body" key={`card-body-${keyId}`}>
          {/* <h5 className="card-title" key={`card-title-${keyId}`}>
            {titleSection()}
          </h5> */}
          <div className="card-text" key={`card-text-${keyId}`}>
            {contentSection()}
          </div>
        </div>
      </div>
    </div>
  );
};

CardTemplate.propTypes = {
  imageSection: PropTypes.func,
  titleSection: PropTypes.func,
  contentSection: PropTypes.func,
  keyId: PropTypes.string.isRequired,
};

export default CardTemplate;
