import React from "react";
import { ActionButton } from "../../utils/functions_dates";

export const getUsersVote = (userId, rank) => {
  // if (!rank || !rank?.votes || !rank?.votes?.length)
  if (!rank?.votes?.length) return defaultVoteSet(userId, rank);
  let userVotes = rank.votes.filter((vote) => vote.user === userId);
  return userVotes?.length ? userVotes[0] : defaultVoteSet(userId, rank);
};

export const defaultVoteSet = (userId, rank) => ({
  user: userId,
  item: rank?._id || "pending",
  // item: getItemId(mode, theMode, voteRecord, rank, current_category),
  again: "",
  stars: "",
  comments: "",
});

export const getItemId = (mode, theMode, voteRecord, rankRecord) => {
  if (theMode.show === mode) {
    return voteRecord.item;
  } else if (theMode.add === mode) {
    return rankRecord._id;
  } else if (theMode.addParent === mode) {
    return rankRecord._id;
  } else if (theMode.edit === mode) {
    return voteRecord.item;
  }
};

export const voteButton = (vote, button, buttonStyle, onSaveAdd) => {
  const fns = {
    btn_Save: ActionButton(
      buttonStyle,
      onSaveAdd,
      <i className="fa fa-save" />,
      {
        data: vote,
        action: "save",
      }
    ),
    btn_Add: ActionButton(
      buttonStyle,
      onSaveAdd,
      <i className="fas fa-plus-circle" />,
      {
        data: vote,
        action: "add",
      }
    ),
    btn_Edit: ActionButton(
      buttonStyle,
      "",
      <i className="far fa-edit" />,
      vote
    ),
    btn_Cancel: ActionButton(buttonStyle, "", "Cancel", ""),
    btn_Delete: ActionButton(buttonStyle, "", "Delete", {
      data: vote,
      action: "delete",
    }),
  };
  // console.log(fns[button]);
  return fns[button];
};

// export const voteButton = (vote, button, buttonStyle, onSaveAdd) => {
//   const fns = {
//     btn_Save: function btn_Save() {
//       return ActionButton(
//         buttonStyle,
//         onSaveAdd,
//         <i className="fa fa-save" />,
//         {
//           data: vote,
//           action: "save",
//         }
//       );
//     },
//     btn_Add: function btn_Add() {
//       return ActionButton(
//         buttonStyle,
//         onSaveAdd,
//         <i className="fas fa-plus-circle" />,
//         {
//           data: vote,
//           action: "add",
//         }
//       );
//     },
//     btn_Edit: function btn_Edit() {
//       return ActionButton(buttonStyle, "", <i className="far fa-edit" />, vote);
//     },
//     btn_Cancel: function btn_Cancel() {
//       return ActionButton(buttonStyle, "", "Cancel", "");
//     },
//     btn_Delete: function btn_Delete() {
//       return ActionButton(buttonStyle, "", "Delete", {
//         data: vote,
//         action: "delete",
//       });
//     },
//   };
//   // console.log(fns[button]);
//   return fns[button];
// };

// const getItemId = (mode, theMode) => {
//     if (theMode.show === mode) {
//       return voteRecord.item;
//     } else if (theMode.add === mode) {
//       return defaultCategory._id;
//     } else if (theMode.addParent === mode) {
//       return defaultCategory._id;
//     } else if (theMode.edit === mode) {
//       return voteRecord.item;
//     }
//   };

// export const getItemId = (
//   mode,
//   theMode,
//   voteRecord,
//   rankRecord,
//   defaultCategory
// ) => {
//   if (theMode.show === mode) {
//     return voteRecord.item;
//   } else if (theMode.add === mode) {
//     return rankRecord._id;
//   } else if (theMode.addParent === mode) {
//     return rankRecord._id;
//   } else if (theMode.edit === mode) {
//     return voteRecord.item;
//   }
// };
