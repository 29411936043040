import React from "react";
import PropTypes from "prop-types";
import Ratings from "react-ratings-declarative"; //https://github.com/ekeric13/react-ratings-declarative

const StarRatings = ({ stars, onChange, name, enabled, size }) => {
  let opts = enabled ? { changeRating: (p, c, n) => onChange(p, c, name) } : {};
  opts.widgetDimensions = size ? size : "25px";

  return (
    <Ratings
      {...opts}
      rating={stars}
      widgetRatedColors="green"
      widgetHoverColors="blue"
      name={name}
    >
      <Ratings.Widget />
      <Ratings.Widget />
      <Ratings.Widget />
      <Ratings.Widget />
      <Ratings.Widget />
    </Ratings>
  );
};

StarRatings.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  stars: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  enabled: PropTypes.bool,
};

export default StarRatings;
