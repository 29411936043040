import React from "react";
import { DateTime } from "luxon";
// import { SingleDatePicker } from 'react-dates';

// @name    parse_passed_date
// @desc    convert the passed date from api call in yyyymmdd format and transform to date value

export const fieldTag = (key, isMobile, data) =>
  isMobile ? (
    <div key={key}>{data}</div>
  ) : (
    <div className="divTableCell" key={key}>
      {data}
    </div>
  );

// export const isArrayEmpty = (obj) =>
//   !(obj !== undefined && obj !== null && obj.length !== 0);

const buttonClasses = [
  { name: "not paid", class: "danger" },
  { name: "not due", class: "secondary" },
  { name: "paid", class: "success" },
];

// takes the list of fields, the record
// const fieldArgs = {arrays: arrays, fields: fields, record: null, onchange: onChange, isMobile: isMobile, bAdd: bAdd}

export const showFields = (args) =>
  args.fields.map((field) => {
    let tagId = createID(field.field, args.record);

    let new_args = {
      tagId: tagId,
      field: findInObj(args.fields, "field", field.field),
      record: args.record,
      onChange: args.onChange,
      add: args.add,
    };

    let data = field.input
      ? field.input[0] === "dropdown"
        ? dropDownInput(args.arrays[field.input[1]], new_args)
        : field.input[0] === "button"
        ? buttonInput(new_args)
        : textInput(new_args)
      : textInput(new_args);

    // if (new_args.field.additional) {
    //   let add = new_args.field.additional
    //   if (add.type.toLowerCase() === 'button') {
    //     let button = ActionButton('btn btn-sm', 'copyField', )
    //   }
    // }

    return fieldTag(tagId, args.isMobile, data);
  });

export const convertToObject = (obj) => JSON.parse(obj);

export const objHasValues = (obj, key) =>
  obj !== undefined &&
  obj !== null &&
  (obj[key] !== undefined) & (obj[key] !== null);

export const buttonInput = (args) => {
  let buttons = args.field.input[1];

  // add, has no record
  // edit, has a record
  // loading only for edit
  return !emptyArr(buttons) &&
    (args.add || objHasKey(args.record, args.field.field)) ? (
    <div
      className="btn-group"
      data-toggle="btn-success"
      key={`btn-group-${args.tagId}`}
    >
      {buttons.map((button) => {
        let buttonClass = findInObj(buttonClasses, "name", button);
        let selected =
          !args.add && args.record[args.field.field] === button
            ? "btn-" + buttonClass.class + " active"
            : "btn-light";

        // 'btn btn-sm btn-' + selected + buttonClass.class,
        return ActionButton("btn btn-sm " + selected, args.onChange, button, [
          args.field.field,
          button,
        ]);
      })}
    </div>
  ) : (
    <span key={args.field.field + "-loading"}>still loading</span>
  );
};

export const dropDownInput = (value_list, args) => {
  args.inputType = "dropdown";

  return !emptyArr(value_list) ? (
    <select {...options(args)}>
      <option key="Please Select">Please Select</option>
      {value_list.map((a) => (
        <option key={a.code}>{a.code}</option>
      ))}
    </select>
  ) : (
    <span key={args.field.field + "-loading"}>still loading</span>
  );
};

const createID = (field, record) => {
  return record !== null && record["_id"] && record["_id"] !== null
    ? field + "-" + record["_id"]
    : field;
};

const options = (args) => {
  let { field } = args.field;
  let opts = {};
  opts.className = "form-control-sm";
  opts.name = field;
  opts.placeholder = field;
  opts.key = field + "-" + args.inputType + "-" + args.tagId;
  opts.onChange = args.onChange;
  if (!args.add)
    opts.value =
      objHasKey(args.record, field) && args.record[field] !== null
        ? args.record[field]
        : "";

  return opts;
};

export const ShowValueOrName = (name, type, value) => {
  let v = formatValue(type, value);
  return v === null || v === "" ? name : v;
};

// <SingleDatePicker
//   name={args.field.field}
//   onDateChange={args.onChange}
//   id={args.tagId}
//   date={args.record[args.field.field]}
//   focused={false}
//   onFocusChange={({ focused }) => this.setState({ focused })}
// />
/* <DatePicker
  name={args.field.field}
  onChange={args.onChange}
  className='form-control'
  value={args.record[args.field.field]}
/> */

export const textInput = (args) => {
  args.inputType = "text";

  return args.field.field === "date_paid" ? (
    <input type="text" {...options(args)} />
  ) : (
    <input type="text" {...options(args)} />
  );
};

export const collapseMultiButton = (
  target,
  controls,
  value,
  classname,
  show,
  onclick
) => (
  <button
    onClick={onclick !== null ? () => onclick() : (e) => e.preventDefault()}
    className={classname}
    data-toggle="collapse"
    data-target={target}
    aria-expanded={show}
    aria-controls={controls}
  >
    {value}
  </button>
);

export const collapseButton = (target, value, classname) => (
  <button
    type="button"
    className={"btn btn-link " + classname}
    data-toggle="collapse"
    data-target={"#" + target}
  >
    {value}
  </button>
);

export const ActionButton = (classname, action, name, param, id) => {
  return (
    <button
      type="button"
      key={`button-${(isObject(name) ? classname : name) + id}`}
      className={classname}
      onClick={() => action(param)}
    >
      {name}
    </button>
  );
};

export const numStrToNumArr = (string) =>
  string.length === 0 ? [] : string.replace(/, +/g, ",").split(",").map(Number);

export const arrStrToNumArr = (arr) => arr.map((num) => parseInt(num));

export const today = () => DateTime.local();

export const confirmVal = (fld) =>
  fld !== undefined && fld !== null ? fld : null;

export const emptyArr = (arr) => !(arr && arr !== null && arr.length > 0);

export const emptyObj = (obj) => !obj || obj === null;

export const getNumber = (val) =>
  val !== undefined && val !== null ? parseFloat(val) : 0;

export const isNumeric = (num) => !isNaN(num);

export const isDate = (d) => {
  if (d !== undefined && d !== null) {
    let m = DateTime.fromISO(d);
    return m.isValid;
  } else {
    return false;
  }
};

export const objHasKey = (obj, key) =>
  !emptyObj(obj) && Object.hasOwnProperty.call(obj, key);

export const buttonCenter = () => (
  <div className="d-flex justify-content-center"></div>
);
export const findInObj = (arr, key, value) => arr.find((o) => o[key] === value);

export const findIndexInObj = (arr, key, value) =>
  arr.findIndex((o) => o[key] === value);

export const isObject = (value) =>
  value && typeof value === "object" && value.constructor === Object;

export const isObjectEmpty = (obj) => !(obj !== undefined && obj !== null);

export const isArrayEmpty = (obj) =>
  !(obj !== undefined && obj !== null && obj.length !== 0);

export const isString = (value) =>
  typeof value === "string" || value instanceof String;

export const isArray = (value) =>
  value && typeof value === "object" && value.constructor === Array;

export const parse_passed_date = (d) => {
  let p = [
    [0, 4],
    [4, 6],
    [6, 8],
  ];
  let r = p.map((ps) => parseInt(d.toString().substring(ps[0], ps[1])));
  return new Date(r[0], r[1] - 1, r[2], 0, 0, 0);
};

export const numberFormat = (value, dec) =>
  value === null ? "" : parseFloat(value).toFixed(dec);

export const currencyFormat = (value) =>
  value === null
    ? ""
    : new Intl.NumberFormat("en-AU", {
        style: "currency",
        currency: "AUD",
      }).format(value);

export const dateFormat = (value) => {
  return value !== undefined && value !== null && value !== ""
    ? new Intl.DateTimeFormat("en-AU").format(new Date(value))
    : "";
};

export const dateTimeFormat = (value) => {
  let format = {
    dateStyle: "short",
    timeStyle: "short",
  };

  format = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  };

  return value !== undefined && value !== null && value !== ""
    ? new Intl.DateTimeFormat("en-AU", format).format(new Date(value))
    : "";
};

export const formatValue = (type, value) => {
  if (value === undefined || value === null) return "";

  switch (type.toLowerCase()) {
    case "date":
      return dateFormat(value);
    case "datetime":
      return dateTimeFormat(value);
    case "number":
      return numberFormat(value, 0);
    case "currency":
      return currencyFormat(value);
    case "float":
      return currencyFormat(value);
    case "array":
      return value.join(",");
    case "string":
    default:
      return value;
  }
};
