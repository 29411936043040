import React, { useEffect, useContext, Fragment } from "react";
import RanksTag from "../rank/Ranks";
import AuthContext from "../../context/auth/authContext";

const RanksBasic = () => {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.loadUser();

    // eslint-disable-next-line
  }, []);

  return <RanksTag />;
};

export default RanksBasic;
