import React, { Fragment, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import AuthContext from "../../context/auth/authContext";

const Navbar = ({ title, icon }) => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, logout, loadUser } = authContext;

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line
  }, []);
  // db-eslint-disable-next-line

  const onLogout = () => {
    logout();
    // clearContacts();
  };

  const authLinks = (
    <Fragment>
      <li className="nav-item">
        <Link className="nav-link text-white" to="/ranks">
          Ranks
        </Link>
      </li>

      <li className="nav-item">
        <a className="nav-link" onClick={onLogout} href="#!">
          Logout <i className="fas fa-sign-out-alt text-white"></i>
        </a>
      </li>
    </Fragment>
  );
  const guestLinks = (
    <Fragment>
      <li className="nav-item">
        <Link className="nav-link text-white" to="/register">
          Register
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link text-white" to="/login">
          Login
        </Link>
      </li>
    </Fragment>
  );

  return (
    <nav className="navbar bg-success mb-1 pb-0">
      <h1 className="text-white">
        <i className={icon} /> {title}
      </h1>
      <ul className="nav align-middle">
        {isAuthenticated ? authLinks : guestLinks}
      </ul>
    </nav>
  );
};

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

Navbar.defaultProps = {
  title: "Ranks",
  icon: "fas fa-id-card-alt",
};

export default Navbar;
