import React, { Fragment, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import AuthContext from "../../context/auth/authContext";
import RankContext from "../../context/rank/rankContext";

import RankNewOrLogin from "./RankNewOrLogin";
import RankItemDetail from "./RankItemDetail";

import VoteList from "../vote/VoteList";
import VoteItemAddEdit from "../vote/VoteItemAddEdit";

import {
  attrOnChange,
  defaultRankSet,
  imageOnChange,
  theMode,
  userCreatedRank,
  voteOnChange,
} from "./rank_resuse";

import { userMatches } from "./rank_resuse";
import { buttonStyle, getButton } from "./rankButtons";
import { defaultVoteSet, getUsersVote } from "../vote/voteReuse";

const RankItem = ({ rankRecord, mode, keyId }) => {
  let lm = "RankItem:";

  const rankContext = useContext(RankContext);
  const {
    current_rank,
    setCurrentRank,
    clearCurrentRank,
    addRank,
    updateRank,
    deleteRank,
    category_selected,
    current_category,
    addVote,
    updateVote,
    setCurrentVote,
    clearCurrentVote,
  } = rankContext;

  const authContext = useContext(AuthContext);
  const { user, isAuthenticated } = authContext;
  const [rank, setRank] = useState({});
  const [voteAddEdit, setVoteAddEdit] = useState(
    defaultVoteSet(user?._id ?? "na", rank?._id)
  );

  // if the user is authenticated, the user created the rank and this is the selected rank
  const isUsersRank = userCreatedRank(rank, current_rank, authContext);
  mode = isAuthenticated && isUsersRank ? theMode.edit : mode;
  const show = theMode.show === mode;
  const add = theMode.add === mode;
  const edit = theMode.edit === mode;
  const key = "rank-" + (rank?._id ?? "add");

  useEffect(() => {
    if (show) {
      setRank(rankRecord);
    } else if (add) {
      setRank(defaultRankSet(current_category?._id ?? "new"));
      // setVoteAddEdit(defaultVoteSet(user._id, rank));
      setVoteAddEdit(defaultVoteSet(user._id, rank));
    } else if (edit) {
      if (current_rank) {
        setRank(current_rank);
        let usersVote = getUsersVote(user._id, rank);
        setVoteAddEdit(usersVote);
        // let usersVote = getUsersVote(user._id, rank);
        // console.log(usersVote);
        // setVoteAddEdit(usersVote);
      } else {
        setRank(rankRecord);
      }
    }
    // eslint-disable-next-line
  }, [current_category, current_rank]);

  const onChange = (...args) => {
    let name = args[2] || args[0].target?.name;
    let chg;

    if (name === "image") {
      chg = imageOnChange(args[0]);
      console.log("------------------------------------");
      console.log(...args);
      console.log("------------------------------------");
    } else if (name.indexOf("vote.") > -1) {
      chg = voteOnChange(name);
    } else if (name.indexOf("attributes") > -1) {
      chg = attrOnChange(rank, args[0]);
    } else if (name.includes("stars")) {
      chg = ["stars", args[0]];
    } else {
      chg = [name, args[0].target.value];
    }

    setRank({
      ...rank,
      [chg[0]]: chg[1],
    });
  };

  const onVoteChange = (...args) => {
    let name = args[2] || args[0].target?.name;
    let chg;
    let chg2;
    // let chg3;

    if (name === "image") {
      chg = imageOnChange(args[0]);
      console.log("------------------------------------");
      console.log(...args);
      console.log("------------------------------------");
    } else if (name.indexOf("vote.") > -1) {
      chg = voteOnChange(name);
    } else if (name.indexOf("attributes") > -1) {
      chg = attrOnChange(rank, args[0]);
    } else if (name.includes("stars")) {
      chg = ["stars", args[0]];
    } else {
      chg = [name, args[0].target.value];
    }

    console.log(voteAddEdit);
    console.log({
      ...voteAddEdit,
      [chg[0]]: chg[1],
    });
    setVoteAddEdit({
      ...voteAddEdit,
      [chg[0]]: chg[1],
    });
  };

  const editRank = () => {
    setCurrentRank(rank);
    let editVote = rank?.votes?.filter((vote) => vote.user === user._id);
    if (editVote) setCurrentVote(editVote[0]);
  };

  const clearAddForm = () => {
    setRank(defaultRankSet);
    clearCurrentRank();
    clearCurrentVote();
  };

  const onSaveAdd = (args) => {
    let rank = args.data;

    if (args.action === "add") {
      addRank(rank);
      addVote(voteAddEdit);
      clearAddForm();
    } else {
      updateRank(rank);
      updateVote(voteAddEdit);
    }
  };

  const onDelete = (obj) => deleteRank(obj?.data?._id);

  const showAddEditCancelButtons = (rank) => {
    return mode === theMode.show ? (
      isAuthenticated && userMatches(rank.user, authContext) ? (
        <div className="row">
          <div className="col">{editButton()}</div>
        </div>
      ) : (
        ""
      )
    ) : (
      <div className="row">
        <div className="col">
          {mode === theMode.edit ? saveButton() : addButton()}
        </div>
        <div className="col">{cancelButton()} </div>
      </div>
    );
  };

  const saveButton = getButton(
    "btn_Save",
    rank,
    // ActionButton,
    buttonStyle,
    onSaveAdd
  );
  const addButton = getButton(
    "btn_Add",
    rank,
    // ActionButton,
    buttonStyle,
    onSaveAdd
  );
  const editButton = getButton(
    "btn_Edit",
    rank,
    // ActionButton,
    buttonStyle,
    editRank
  );
  const deleteButton = getButton(
    "btn_Delete",
    rank,
    // ActionButton,
    buttonStyle,
    onDelete
  );
  const cancelButton = getButton(
    "btn_Cancel",
    rank,
    // ActionButton,
    buttonStyle,
    clearCurrentRank
  );

  const [showVotesList, setShowVotesList] = useState({});
  const setShowVoteList = (id, showHide) => {
    setShowVotesList({
      ...showVotesList,
      [id]: showHide,
    });
  };

  const voteControl = () => {
    if (mode === theMode.show) {
      return (
        <div>
          <VoteList
            mode={mode}
            rank={rank}
            onSaveAdd={onSaveAdd}
            onVoteChange={onVoteChange}
          />
        </div>
      );
    } else {
      let usersVote = getUsersVote(user._id, rank);
      return !usersVote ? (
        ""
      ) : (
        <VoteItemAddEdit
          mode={mode}
          vote={voteAddEdit}
          onChange={onVoteChange}
          isUsersVote={true}
          onSaveAdd={onSaveAdd}
          keyId={"vote-item-" + mode}
          key={"vote-item-" + mode}
        />
      );
    }
  };

  const voteSection = () => {
    let showVotes = showVotesList[rank?._id] || theMode.edit === mode;
    return (
      <Fragment>
        <div className="row" key={`card-content-r6-${keyId}`}>
          <div className="col  text-center" key={`card-content-r6c1-${keyId}`}>
            <button
              className="buttonAsLink"
              key={`btn-show-${keyId}`}
              onClick={() => setShowVoteList(rank._id, !showVotes)}
            >
              {showVotes ? "hide " : "show "} votes ({rank?.voteCount ?? 0})
            </button>
          </div>
        </div>
        <div className="row" key={`card-content-r4-${keyId}`}>
          <div className="col" key={`card-content-r4c1-${keyId}`}>
            {showVotes ? (
              <Fragment>
                <hr />
                <div className="row" key={`showVotesList-row-${keyId}`}>
                  <div className="col" key={`showVotesList-col-${keyId}`}>
                    {voteControl(theMode, mode)}
                  </div>
                </div>
              </Fragment>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row mt-1" key={`card-content-r5-${keyId}`}>
          <div
            className="col m-1 text-center"
            key={`card-content-r5c1-${keyId}`}
          >
            {showAddEditCancelButtons(rank)}
          </div>
        </div>
      </Fragment>
    );
  };

  if (add && (!isAuthenticated || !category_selected))
    return (
      <RankNewOrLogin
        mode={mode}
        theMode={theMode}
        catSelected={category_selected}
        loggedIn={isAuthenticated}
        keyId={keyId}
      />
    );

  return (
    <Fragment>
      <RankItemDetail
        rank={rank}
        keyId={keyId}
        theMode={theMode}
        mode={mode}
        onChange={onChange}
        onSaveAdd={onSaveAdd}
        onDelete={onDelete}
      />
      {voteSection()}
    </Fragment>
  );
};

RankItem.propTypes = {
  mode: PropTypes.number,
  rankRecord: PropTypes.object,
  keyId: PropTypes.string,
};

export default RankItem;
