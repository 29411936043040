import { ActionButton } from "../../utils/functions_dates";
import { buttonPrefix } from "./rank_resuse";

// const hasVotes = rank?.votes || rank?.votes === 0;
export const buttonPre = (rank, current_category) =>
  buttonPrefix(rank, current_category);

export const buttonStyle = "btn btn-sm btn-primary";
// fa fa-check

export const getButton = (button, rank, buttonStyle, func) => {
  const fns = {
    btn_Save: function btn_Save() {
      return ActionButton(
        buttonStyle,
        func,
        "Save Rank",
        {
          data: rank,
          action: "save",
        },
        "save-" + rank._id
      );
    },
    btn_Add: function btn_Add() {
      return ActionButton(
        buttonStyle,
        func,
        "Add Rank",
        {
          data: rank,
          action: "add",
        },
        "add-" + rank._id
      );
    },
    btn_Edit: function btn_Edit() {
      return ActionButton(
        buttonStyle,
        func,
        "Edit Rank",
        rank,
        "edit-" + rank._id
      );
    },
    btn_Cancel: function btn_Cancel() {
      return ActionButton(
        buttonStyle,
        func,
        "Cancel",
        {},
        "cancel-" + rank._id
      );
    },
    btn_Delete: function btn_Delete() {
      return ActionButton(
        buttonStyle,
        func,
        "Delete Rank",
        {
          data: rank,
          action: "delete",
        },
        "delete-" + rank._id
      );
    },
    // btn_Cancel: function btn_Cancel() {
    //   return ActionButton(
    //     buttonStyle,
    //     func,
    //     "Cancel",
    //     {},
    //     "cancel-" + rank._id
    //   );
    // },
  };

  return fns[button];
};
