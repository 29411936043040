import {
  GET_RANKS,
  ADD_RANK,
  DELETE_RANK,
  UPDATE_RANK,
  GET_CATEGORYS,
  GET_CATEGORY,
  ADD_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
  SET_CURRENT_CATEGORY,
  CLEAR_CURRENT_CATEGORY,
  FILTER_CATEGORY,
  CLEAR_FILTER_CATEGORY,
  SET_CURRENT_RANK,
  CLEAR_CURRENT_RANK,
  FILTER_RANK,
  CLEAR_FILTER_RANK,
  GET_VOTES,
  SET_CURRENT_VOTE,
  CLEAR_CURRENT_VOTE,
  ADD_VOTE,
  DELETE_VOTE,
  UPDATE_VOTE,
} from "../types_rank";
// import { isObject } from '../../utils/functions_dates';

const rankReducer = (state, action) => {
  switch (action.type) {
    case GET_RANKS:
      return {
        ...state,
        ranks: action?.payload ? action?.payload : [],
        loading_rank: false,
      };

    case ADD_RANK:
      return {
        ...state,
        ranks: [action.payload, ...state.ranks],
        loading_rank: false,
        // as above state is being passed and state is immutable, need to make a copy
      };

    case UPDATE_RANK:
      // update rank array and update current_rank if it's the same
      return {
        ...state,
        ranks: state.ranks.map((rank) => {
          return rank._id === action.payload._id ? action.payload : rank;
        }),
        // current_rank:
        //   state.current_rank?._id === action.payload?._id
        //     ? action.payload
        //     : state.current_rank,
        loading_rank: false,
      };

    case DELETE_RANK:
      return {
        ...state,
        ranks: state.ranks.filter((rank) => rank._id !== action.payload),
        loading_rank: false,
      };

    case GET_CATEGORYS:
      return {
        ...state,
        categorys: action.payload,
        loading_category: false,
      };

    case GET_CATEGORY:
      return {
        ...state,
        category: action.payload,
        loading_category: false,
      };

    case ADD_CATEGORY:
      return {
        ...state,
        categorys: [action.payload, ...state.categorys],
        loading_category: false,

        // as above state is being passed and state is immutable, need to make a copy
      };

    case UPDATE_CATEGORY:
      return {
        ...state,
        categorys: state.categorys.map((category) =>
          category._id === action.payload._id ? action.payload : category
        ),
        loading_category: false,

        // as above state is being passed and state is immutable, need to make a copy
      };

    case DELETE_CATEGORY:
      return {
        ...state,
        categorys: state.categorys.filter(
          (category) => category._id !== action.payload
        ),
        loading_category: false,
      };

    case GET_VOTES:
      console.log("ctx.rankreducer.get_votes: payload");
      console.log(action.payload);
      return {
        ...state,
        votes: action.payload,
        loading_votes: false,
      };

    case ADD_VOTE:
      return {
        ...state,
        votes: [action.payload, ...state.votes],
        loading_votes: false,

        // as above state is being passed and state is immutable, need to make a copy
      };

    case UPDATE_VOTE:
      console.log("ctx.rankreducer.update_votes: state.votes");
      console.log(state.votes);
      return {
        ...state,
        votes: state.votes.map((vote) =>
          vote._id === action.payload._id ? action.payload : vote
        ),
        loading_votes: false,

        // as above state is being passed and state is immutable, need to make a copy
      };

    case DELETE_VOTE:
      return {
        ...state,
        votes: state.votes.filter((vote) => vote._id !== action.payload),
        loading_votes: false,
      };

    case SET_CURRENT_CATEGORY:
      return {
        ...state,
        category_selected: true,
        current_category: action.payload,
        loading_category: false,
      };

    case CLEAR_CURRENT_CATEGORY:
      return {
        ...state,
        category_selected: false,
        current_category: null,
        loading_category: false,
      };

    case FILTER_CATEGORY:
      return {
        ...state,
        ranks_filtered: true,
        filtered_category: action.payload,
        loading_category: false,
      };

    case CLEAR_FILTER_CATEGORY:
      return {
        ...state,
        ranks_filtered: false,
        filtered_category: null,
        loading_category: false,
      };

    case SET_CURRENT_RANK:
      return {
        ...state,
        rank_selected: true,
        current_rank: action.payload,
        loading_rank: false,
      };

    case CLEAR_CURRENT_RANK:
      return {
        ...state,
        rank_selected: false,
        current_rank: null,
        loading_rank: false,
      };
    case SET_CURRENT_VOTE:
      return {
        ...state,
        vote_selected: true,
        current_vote: action.payload,
        loading_vote: false,
      };

    case CLEAR_CURRENT_VOTE:
      return {
        ...state,
        vote_selected: false,
        current_vote: null,
        loading_vote: false,
      };

    case FILTER_RANK:
      return {
        ...state,
        filtered_rank: action.payload,
        loading_rank: false,
      };

    case CLEAR_FILTER_RANK:
      return {
        ...state,
        filtered_rank: null,
        loading_rank: false,
      };

    default:
      return state;
  }
};

export default rankReducer;
