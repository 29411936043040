import React, { Fragment, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import RankContext from "../../context/rank/rankContext";
import AuthContext from "../../context/auth/authContext";

import VoteItemShow from "./VoteItemShow";
import VoteItemAddEdit from "./VoteItemAddEdit";
import Spinner from "../layout/Spinner";

import { isArrayEmpty, getUserID } from "../rank/rank_resuse";
import { defaultVoteSet } from "./voteReuse";

const VoteList = ({ mode, rank, onSaveAdd, onVoteChange }) => {
  const authContext = useContext(AuthContext);
  const rankContext = useContext(RankContext);
  const { votes, getVotes, loading_votes } = rankContext;

  const [showAddVote, setShowAddVote] = useState(false);

  //mode = 0 -> display, 1 -> edit, 2 -> add
  const show = 0,
    edit = 1,
    add = 2;

  // if action === show, show the vote record
  // if action === add, show empty form with rank._id populated
  // if action === edit, show the vote item that is the users vote

  let userId = getUserID(authContext);
  const usersVotes = isArrayEmpty(votes)
    ? 0
    : votes.filter((vote) => vote.user === userId);
  const userHasVoted = userId && usersVotes.length > 0;

  useEffect(() => {
    if (mode !== add) {
      getVotes({ item: rank._id });
    }
    // eslint-disable-next-line
  }, []);

  // show the vote control for show or edit
  const voteItemControl = (vote, mode, isUsersVote) => {
    let voteRecord =
      mode === add ? (
        <VoteItemShow
          mode={add}
          voteRecord={defaultVoteSet}
          onVoteChange={onVoteChange}
          onSaveAdd={onSaveAdd}
          isUsersVote={false}
          rank={rank}
          onSaveAddRank={onSaveAdd}
          keyId={"vote-item-add"}
          key={"vote-item-add"}
        />
      ) : (
        mode ===
        edit(
          <VoteItemShow
            mode={mode === edit && isUsersVote ? edit : show}
            voteRecord={vote}
            onVoteChange={onVoteChange}
            onSaveAdd={onSaveAdd}
            isUsersVote={isUsersVote}
            rank={rank}
            onSaveAddRank={onSaveAdd}
            keyId={"vote-item-" + vote._id}
            key={"vote-item-" + vote._id}
          />
        )
      );
  };

  const wrapTag = (voteId, voteItemControl) => (
    <div key={"vote-item-" + voteId}>
      <hr />
      {voteItemControl}
    </div>
  );

  const showVoteList = () => {
    let i = 0;
    if (isArrayEmpty(votes)) return "";

    return votes.map((vote) => {
      if (vote.item !== rank._id) return null;
      let isUsersVote = userId === vote.user; // check whether to show edit for the vote or not
      console.log(">>>", isUsersVote, "<<<");
      let voteItemHTML = voteItemControl(vote, mode, isUsersVote);
      if (i !== 0) voteItemHTML = wrapTag(vote._id, voteItemHTML); // wrap tag in dig if not the first item
      i++;
      return voteItemHTML;
    });
  };

  const addVoteForm = () => {
    let k = "vote-add-";
    // let title = "Vote"
    return (
      <Fragment>
        <hr />

        <div className="row align-items-center">
          <div className="col-auto">
            <button
              className="border-0 bg-white"
              onClick={() => setShowAddVote(!showAddVote)}
              key={k + "button"}
            >
              <span
                className="material-symbols-outlined"
                key={k + "button-sign"}
              >
                {!showAddVote ? "add_circle" : "cancel"}
              </span>{" "}
            </button>
          </div>
          <div className="col">
            <span key={k + `text`}>Add Vote</span>
          </div>
        </div>
        <div className="row ">
          <div className="col">
            {!showAddVote ? "" : voteItemControl(defaultVoteSet, mode, false)}
          </div>
        </div>
      </Fragment>
    );
  };

  if (loading_votes) return <Spinner />;
  return (
    <Fragment>
      <div key={"votelist-show"}>{showVoteList()}</div>
      {userHasVoted ? "" : <div key={"votelist-showAdd"}>{addVoteForm()}</div>}
    </Fragment>
  );
};

VoteList.propTypes = {
  mode: PropTypes.number,
  rank: PropTypes.object,
  onSaveAdd: PropTypes.func,
  onVoteChange: PropTypes.func,
};

export default VoteList;
