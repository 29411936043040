import React, { useReducer } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import RankContext from "./rankContext";
import rankReducer from "./rankReducer";
import {
  GET_RANKS,
  ADD_RANK,
  DELETE_RANK,
  UPDATE_RANK,
  RANK_ERROR,
  GET_CATEGORYS,
  GET_CATEGORY,
  ADD_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
  CATEGORY_ERROR,
  GET_VOTES,
  ADD_VOTE,
  DELETE_VOTE,
  UPDATE_VOTE,
  VOTE_ERROR,
  SET_CURRENT_RANK,
  CLEAR_CURRENT_RANK,
  FILTER_RANK,
  CLEAR_FILTER_RANK,
  SET_CURRENT_CATEGORY,
  CLEAR_CURRENT_CATEGORY,
  FILTER_CATEGORY,
  CLEAR_FILTER_CATEGORY,
  SET_CURRENT_VOTE,
  CLEAR_CURRENT_VOTE,
  FILTER_VOTE,
  CLEAR_FILTER_VOTE,
} from "../types_rank";
import { dbMsg, dbMsgLarge } from "../../utils/functionsCommon";
// import { isObject } from '../../utils/functions_dates';

let debug = 0;
let db = debug >= 1;
let dp = "com.rankstate";

const RankState = (props) => {
  const initialState = {
    current: null,
    ranks: null,
    current_rank: null,
    rank_selected: false,
    ranks_filtered: false,
    filtered_rank: false,
    loading_rank: true,

    category: null,
    categorys: null,
    current_category: null,
    category_selected: false,
    filtered_category: null,
    loading_category: true,

    votes: null,
    vote_selected: null,
    current_vote: null,
    filtered_votes: null,
    loading_votes: true,
    filtered: null,
    error: null,
  };

  const [state, dispatch] = useReducer(rankReducer, initialState);

  // Add Contact
  const addRank = async (rank) => {
    let lm = dp + ".addRank";
    // not sending token as its send locally
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      dbMsg(1, debug, `${lm}start `);

      let file = rank.image;
      if (file !== null && file !== "") {
        const formData = new FormData();
        formData.append("file", file);

        await axios
          .post(`/api/ranks/s3upload`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            rank.image = res.data.key;
          });
      }

      await axios.post("/api/ranks", rank, config).then(async (newRank) => {
        // try registering user with form data and json config
        let vote = {};
        if (newRank._id) vote.item = newRank._id;
        if (newRank.stars) vote.stars = newRank.stars;
        if (newRank.vote) vote.vote = newRank.vote;
        if (newRank.comments) vote.comments = newRank.comments;

        dbMsgLarge(0, debug, `${lm}New Rank:`, newRank);

        await axios.post("/api/ranks/votes", vote, config).then((newVote) => {
          dispatch({
            type: ADD_RANK,
            payload: newRank.data,
          });

          dispatch({
            type: ADD_VOTE,
            payload: newVote.data,
          });
        });
      });

      dbMsg(1, debug, `${lm}finish `);
    } catch (err) {
      dbMsgLarge(0, debug, `${lm} Error:-`, err);
      dispatch({
        type: RANK_ERROR,
        payload: err.message,
      });
    }
  };

  // Update Contact
  const updateRank = async (rank) => {
    let lm = (dp += ".updateRank: ");
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    dbMsg(1, debug, `${lm} Starting to update Rank`);
    dbMsgLarge(2, debug, `${lm} Rank record passed:`, rank);

    dbMsg(1, debug, `${lm} Reading rank record:` + rank._id);
    try {
      // TO-DO ?? does this call need to be done if we have passed the form with the rank id?
      //   seems not required
      await axios
        .get(`/api/ranks/rank/${rank._id}`)
        .then(async (rankRecord) => {
          dbMsg(1, debug, `${lm} Reading passed form data`);

          dbMsg(1, debug, `${lm} Checking if need to upload file`);
          let file = rank.image;
          if (file?.type === "image/jpeg") {
            dbMsg(1, debug, `${lm} Reading file from form`);
            const formData = new FormData();
            formData.append("file", file);

            dbMsg(1, debug, `${lm} Uploading file to S3`);
            await axios
              .post(`/api/ranks/s3upload`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((res) => {
                dbMsg(1, debug, `${lm} Uploaded file successfully`);
                rank.image = res.data.key;
              });
          }
          return rankRecord;
        })
        .then(async (record) => {
          dbMsg(1, debug, `${lm} Updating Rank record`);
          dbMsg(1, debug, `${lm} Calling update query`);
          await axios
            .put(`/api/ranks/${rank._id}`, rank, config)
            .then((res) => {
              dbMsgLarge(2, debug, `${lm} Updated rank record:-`, res.data);

              dbMsg(1, debug, `${lm} Updating context`);
              dispatch({ type: UPDATE_RANK, payload: res.data });
            });
        })
        .catch((err) => {
          dbMsgLarge(0, debug, `${lm} Error:-`, err);
          dispatch({
            type: RANK_ERROR,
            payload: err.response.msg,
          });
        });
      dbMsg(1, debug, `${lm} Finished update`);
    } catch (err) {
      dbMsgLarge(0, debug, `${lm} Error:-`, err);
      dispatch({
        type: RANK_ERROR,
        payload: err.response.msg,
      });
    }
  };

  // Get Ranks
  const getRanksSet = async (filters) => {
    let lm = dp + ".getRanksSet: ";
    try {
      dbMsg(1, debug, `${lm}start`);
      let res = [];
      res = await getEnrichedRanks(filters);
      if (res.data) {
        dispatch({
          type: GET_RANKS,
          payload: res.data,
        });
      }

      dbMsg(1, debug, `${lm}end`);
    } catch (err) {
      dbMsgLarge(0, debug, `${lm} Error:-`, err);

      dispatch({
        type: RANK_ERROR,
        payload: err.message,
      });
    }
  };

  // Get Ranks
  const getEnrichedRanks = async (filters) => {
    let lm = dp + ".getEnrichedRanks";
    try {
      dbMsg(1, debug, `${lm}start`);
      if (filters) {
        for (const property in filters)
          if (filters[property] === "" || filters[property] === null)
            delete filters[property];
      }
      let res = [];
      res = await axios.get(`/api/ranks/enriched/`, { params: filters });
      // if (res.data) {
      dbMsg(1, debug, `${lm}end`);
      return res;
      // }
    } catch (err) {
      dbMsgLarge(0, debug, `${lm} Error:-`, err);
    }
  };

  // Get Ranks
  const getRanks = async (filters) => {
    let lm = dp + ".getRanks";
    try {
      dbMsg(1, debug, `${lm}Start`);
      if (filters) {
        for (const property in filters)
          if (filters[property] === "" || filters[property] === null)
            delete filters[property];
      }

      let res = [];
      res = await axios.get(`/api/ranks/`, { params: filters });

      dispatch({
        type: GET_RANKS,
        payload: res.data,
      });

      dbMsg(1, debug, `${lm}end`);
    } catch (err) {
      dbMsgLarge(0, debug, `${lm} Error:-`, err);

      dispatch({
        type: RANK_ERROR,
        payload: err.message,
      });
    }
  };

  const deleteRank = async (id) => {
    let lm = dp + ".deleteRank: ";
    try {
      // try registering user with form data and json config
      await axios.delete(`/api/ranks/${id}`);

      dispatch({ type: DELETE_RANK, payload: id });
    } catch (err) {
      dbMsgLarge(0, debug, `${lm} Error:-`, err);

      dispatch({
        type: RANK_ERROR,
        payload: err.response.msg,
      });
    }
  };

  // Get Categorys
  const getCategorys = async (filters) => {
    let lm = dp + ".getCategorys: ";
    try {
      dbMsg(1, debug, `${lm}Start`);
      if (filters) {
        for (const property in filters)
          if (filters[property] === "" || filters[property] === null)
            delete filters[property];
      }

      let res = [];
      res = await axios.get(`/api/ranks/categorys/`, { params: filters });

      dispatch({
        type: GET_CATEGORYS,
        payload: res.data,
      });

      dbMsg(1, debug, `${lm}End`);
    } catch (err) {
      dbMsgLarge(0, debug, `${lm} Error:-`, err);

      dispatch({
        type: CATEGORY_ERROR,
        payload: err.message,
      });
    }
  };

  // Get Category
  const getCategory = async (filters) => {
    let lm = dp + ".getCategory: ";
    try {
      dbMsg(1, debug, `${lm}Start`);
      if (filters) {
        for (const property in filters)
          if (filters[property] === "" || filters[property] === null)
            delete filters[property];
      }

      let res = [];
      res = await axios.get(`/api/ranks/category/`, { params: filters });

      dispatch({
        type: GET_CATEGORY,
        payload: res.data,
      });

      dbMsg(1, debug, `${lm}Finished`);
    } catch (err) {
      dbMsgLarge(0, debug, `${lm} Error:-`, err);

      dispatch({
        type: CATEGORY_ERROR,
        payload: err.message,
      });
    }
  };

  // Add Contact
  const addCategory = async (category) => {
    let lm = dp + ".addCategory: ";
    // not sending token as its send locally
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // try registering user with form data and json config
      const res = await axios.post("/api/ranks/categorys", category, config);
      dbMsgLarge(1, debug, `${lm}res:- `, res);

      dispatch({
        type: ADD_CATEGORY,
        payload: res.data,
      });
    } catch (err) {
      dbMsgLarge(0, debug, `${lm} Error:-`, err);

      dispatch({
        type: CATEGORY_ERROR,
        payload: err.response.msg,
      });
    }
  };

  // Update Contact
  const updateCategory = async (category) => {
    let lm = dp + ".updateCategory: ";
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // as we are being passed in the whole contact, we need to just refer
      //    to the ._id specifically
      const res = await axios.put(
        `/api/ranks/categorys/${category._id}`,
        category,
        config
      );

      // using res.data instead of the contact passed in as we want to get the
      //   contact item from the db instead of the argument
      dispatch({ type: UPDATE_CATEGORY, payload: res.data });
    } catch (err) {
      dbMsgLarge(0, debug, `${lm} Error:-`, err);

      dispatch({
        type: CATEGORY_ERROR,
        payload: err.response.msg,
      });
    }
  };

  const deleteCategory = async (id) => {
    let lm = dp + ".deleteCategory: ";
    try {
      // try registering user with form data and json config
      await axios.delete(`/api/ranks/categorys/${id}`);

      dispatch({ type: DELETE_CATEGORY, payload: id });
    } catch (err) {
      dbMsgLarge(0, debug, `${lm} Error:-`, err);

      dispatch({
        type: CATEGORY_ERROR,
        payload: err.response.msg,
      });
    }
  };

  // Get Votes
  const getVotes = async (filters) => {
    let lm = dp + ".getVotes: ";
    try {
      dbMsg(1, debug, `${lm}Start`);
      dbMsg(1, debug, `${lm}Determining filter`);

      if (filters) {
        for (const property in filters)
          if (filters[property] === "" || filters[property] === null)
            delete filters[property];
      }

      dbMsgLarge(1, debug, `${lm}Filters:-`, filters);

      dbMsg(1, debug, `${lm}Sending request for votes`);
      let res = [];
      res = await axios.get(`/api/ranks/votes/`, { params: filters });

      dbMsg(1, debug, `${lm}Received ` + (res?.data?.length ?? 0) + ` votes`);

      dispatch({
        type: GET_VOTES,
        payload: res.data,
      });

      dbMsg(1, debug, `${lm}Finished`);
    } catch (err) {
      dbMsgLarge(0, debug, `${lm} Error:-`, err);
      dispatch({
        type: VOTE_ERROR,
        payload: err.message,
      });
    }
  };

  // Add Contact
  const addVote = async (vote) => {
    let lm = dp + ".addVote: ";
    // not sending token as its send locally
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      dbMsgLarge(2, debug, `${lm}vote:-`, vote);

      // try registering user with form data and json config
      const res = await axios.post("/api/ranks/votes", vote, config);
      dbMsgLarge(2, debug, `${lm}res:-`, res);

      dispatch({
        type: ADD_VOTE,
        payload: res.data,
      });
    } catch (err) {
      dbMsgLarge(0, debug, `${lm} Error:-`, err);

      dispatch({
        type: VOTE_ERROR,
        payload: err.response.msg,
      });
    }
  };

  // Update Contact
  const updateVote = async (vote) => {
    let lm = (dp += ".updateVote:");
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dbMsg(0, debug, `${lm}Start`);

    try {
      // as we are being passed in the whole contact, we need to just refer
      //    to the ._id specifically
      dbMsg(0, debug, `${lm}Posting updated vote`);

      let res = await axios.put(`/api/ranks/votes/${vote._id}`, vote, config);
      dbMsg(0, debug, `${lm}Update call made`);

      // using res.data instead of the contact passed in as we want to get the
      //   contact item from the db instead of the argument
      dispatch({ type: UPDATE_VOTE, payload: res.data });
      dbMsg(0, debug, `${lm}Get refreshed votes`);
      // getVotes({ item: vote.item });

      dbMsg(0, debug, `${lm}Finished`);
    } catch (err) {
      dbMsgLarge(0, debug, `${lm} Error:-`, err);
      dispatch({
        type: VOTE_ERROR,
        payload: err.message,
      });
    }
  };

  const deleteVote = async (id) => {
    let lm = dp + ".deleteVote: ";
    try {
      // try registering user with form data and json config
      await axios.delete(`/api/ranks/votes/${id}`);

      dispatch({ type: DELETE_VOTE, payload: id });
    } catch (err) {
      dbMsgLarge(0, debug, `${lm} Error:-`, err);

      dispatch({
        type: VOTE_ERROR,
        payload: err.message,
      });
    }
  };

  // const setCurrentRank = (rank) => {
  //   dispatch({ type: SET_CURRENT_RANK, payload: rank });
  // };

  const setCurrentRank = async (rank) => {
    let lm = dp + ".setCurrentRank";
    try {
      dbMsg(1, debug, `${lm}start`);
      let res = [];
      res = await getEnrichedRanks({ rank_id: rank._id });
      if (res.data) {
        dispatch({
          type: SET_CURRENT_RANK,
          payload: res.data[0],
        });
      }

      dbMsg(1, debug, `${lm}end`);
    } catch (err) {
      dbMsgLarge(0, debug, `${lm} Error:-`, err);

      dispatch({
        type: RANK_ERROR,
        payload: err.message,
      });
    }

    // dispatch({ type: SET_CURRENT_RANK, payload: rank });
  };

  const clearCurrentRank = () => {
    dispatch({ type: CLEAR_CURRENT_RANK });
  };

  // Filter Contacts
  const filterRanks = (text) => {
    dispatch({ type: FILTER_RANK, payload: text });
  };

  // Clear Filter
  const clearRankFilter = () => {
    dispatch({ type: CLEAR_FILTER_RANK });
  };

  const setCurrentCategory = (category) => {
    dispatch({ type: SET_CURRENT_CATEGORY, payload: category });
  };

  const clearCurrentCategory = () => {
    dispatch({ type: CLEAR_CURRENT_CATEGORY });
  };

  // Filter Contacts
  const filterCategorys = (text) => {
    dispatch({ type: FILTER_CATEGORY, payload: text });
  };

  // Clear Filter
  const clearCategoryFilter = () => {
    dispatch({ type: CLEAR_FILTER_CATEGORY });
  };

  const setCurrentVote = (vote) => {
    dispatch({ type: SET_CURRENT_VOTE, payload: vote });
  };

  const clearCurrentVote = () => {
    dispatch({ type: CLEAR_CURRENT_VOTE });
  };

  // Filter Contacts
  const filterVotes = (text) => {
    dispatch({ type: FILTER_VOTE, payload: text });
  };

  // Clear Filter
  const clearVoteFilter = () => {
    dispatch({ type: CLEAR_FILTER_VOTE });
  };

  return (
    <RankContext.Provider
      value={{
        filtered: state.filtered,
        current: state.current,

        ranks: state.ranks,
        loading_rank: state.loading_rank,
        current_rank: state.current_rank,
        rank_selected: state.rank_selected,
        filtered_rank: state.filtered_rank,

        getRanks,
        getRanksSet,
        addRank,
        updateRank,
        deleteRank,

        setCurrentRank,
        clearCurrentRank,
        filterRanks,
        clearRankFilter,

        category: state.category,
        categorys: state.categorys,
        current_category: state.current_category,
        category_selected: state.category_selected,
        loading_category: state.loading_category,
        filtered_category: state.filtered_category,

        getCategorys,
        getCategory,
        addCategory,
        updateCategory,
        deleteCategory,

        setCurrentCategory,
        clearCurrentCategory,
        filterCategorys,
        clearCategoryFilter,

        votes: state.votes,
        vote_selected: state.vote_selected,
        current_vote: state.current_vote,
        loading_votes: state.loading_votes,
        filtered_votes: state.filtered_votes,

        getVotes,
        addVote,
        updateVote,
        deleteVote,

        setCurrentVote,
        clearCurrentVote,
        filterVotes,
        clearVoteFilter,

        error: state.error,
      }}
    >
      {props.children}
    </RankContext.Provider>
  );
};

RankState.propTypes = {
  children: PropTypes.object,
};

export default RankState;
