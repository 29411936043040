import React, { Fragment } from "react";
import PropTypes from "prop-types";

import StarRatings from "./StarRatings";

import { showAgainButtons } from "../rank/rank_resuse";

const AgainStarsComments = ({
  record,
  theMode,
  mode,
  buttonPre,
  onChange,
  keyId,
}) => {
  // const hasVotes = record?.votes || record?.votes === 0;
  const show = theMode.show === mode;
  let stars = record?.avgStars || record?.stars || 0;
  const _id = show ? record?._id : "add";
  // const key = "rank-" + _id;

  const showComments = (record) =>
    mode === theMode.show ? (
      <div className="small text-center">{record.comments}</div>
    ) : (
      <textarea
        type="text"
        className="grid-input-acct "
        name="comments"
        placeholder="comments"
        onChange={onChange}
        // onChange={mode === theMode.add ? onVoteChange : onChange}
        rows="3"
        value={record.comments}
      />
    );

  return (
    <Fragment>
      <div className="row" key={`card-content-r1-${keyId}`}>
        <div className="col text-center" key={`card-content-r1c1-${keyId}`}>
          {showAgainButtons(mode, theMode, show, record, buttonPre, onChange)}
        </div>
      </div>
      <div className="row mt-1" key={`card-content-r3-${keyId}`}>
        <div className="col  text-center" key={`card-content-r3c1-${keyId}`}>
          <StarRatings
            name={"stars-rating-" + _id}
            stars={stars}
            size={"25px"}
            onChange={onChange}
            enabled={!show}
          />
        </div>
      </div>
      <div className="row mt-1" key={`card-content-r4-${keyId}`}>
        <div
          className="col d-flex justify-content-center"
          key={`card-content-r4c1-${keyId}`}
        >
          {showComments(record)}
        </div>
      </div>
    </Fragment>
  );
};

AgainStarsComments.propTypes = {
  mode: PropTypes.number,
  record: PropTypes.object,
  theMode: PropTypes.object,
  buttonPre: PropTypes.string,
  onChange: PropTypes.func,
  keyId: PropTypes.string,
};

export default AgainStarsComments;
