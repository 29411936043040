import React, { Fragment } from "react";
import PropTypes from "prop-types";

const NameLink = ({
  rank,
  current_rank,
  theMode,
  mode,
  onChange,
  clearCurrentRank,
  setCurrentRank,
}) => {
  // if (mode === theMode.add) return <p>New item to rate!</p>;
  let name = rank?.name || "add-rank";
  let editingRank = theMode.edit === mode && current_rank?._id === rank?._id;
  const rankSelected = !!current_rank;

  const showNameInput = (rank) => {
    const nameInput = () => {
      let options = { name: "name" };
      options.className = "grid-input-acct";
      options.placeholder = "name";
      options.type = "text";
      options.placeholder = rank?.name || "enter a name";
      options.value = rank?.name;
      options.name = "name";

      return <input {...options} onChange={onChange} />;
    };

    return (
      <div>
        <Fragment>{nameInput()}</Fragment>
      </div>
    );
  };

  if (editingRank || mode === theMode.add) return showNameInput(rank);
  return (
    // <div>
    //   {editingRank || mode === theMode.add ? (
    //     showNameInput(rank)
    //   ) : rankSelected ? (
    // <Fragment>
    <button
      className="buttonAsLink"
      value={name}
      onClick={
        rankSelected ? () => clearCurrentRank() : () => setCurrentRank(rank)
      }
    >
      {name}
    </button>
    // </Fragment>
    //   ) : (
    //     <button
    //       className="buttonAsLink"
    //       value={name}
    //       onClick={() => setCurrentRank(rank)}
    //     >
    //       {name}
    //     </button>
    //   )}
    // </div>
  );
};

NameLink.propTypes = {
  mode: PropTypes.number,
  rank: PropTypes.object,
  current_rank: PropTypes.object,
  theMode: PropTypes.object,
  onChange: PropTypes.func,
  clearCurrentRank: PropTypes.func,
  setCurrentRank: PropTypes.func,
};

export default NameLink;
