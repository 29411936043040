import React, { Fragment, useContext, useEffect } from "react";

import RankContext from "../../context/rank/rankContext";

import CategoryMap from "../category/CategoryMap";
import CategorySelect from "../category/CategorySelect";

import RankList from "./RankList";
// import { isObject } from "../../utils/functions_dates";

let debug = 0;
// const db = debug >= 1 ? true : false;

const Ranks = () => {
  let dp = ".com.ranks";

  // add rank
  //    - show rank form as add mode
  //    - show vote form as add mode
  //    - on add, split rank as rank item and vote as vote item
  // show rank
  //    - show rank form in read only mode
  //    - show edit button on rank if created by user
  //    - show vote form in read only mode
  //    - show edit button on vote if created by user
  // edit rank
  //    - show rank form in edit mode
  //    - show vote form in edit mode
  //    - default the vote data from the vote the user created
  //    - show save and cancel buttons

  // const authContext = useContext(AuthContext);
  const rankContext = useContext(RankContext);
  const { category_selected, current_category } = rankContext;

  // const category_selected = () =>
  //   current_category !== undefined &&
  //   current_category !== null &&
  //   isObject(current_category);

  return (
    <Fragment>
      <div className="container m-0 p-2">
        <div className="row mt-1">
          <div className="col h3">
            {category_selected
              ? current_category.prefix + " again"
              : "Select Category"}
          </div>
          {/* <div>{current_category?.name || "not selected"}</div> */}
        </div>
        <div className="row mt-1">
          <div className="col">
            <CategorySelect />
          </div>
        </div>
        <div className="row mt-1">
          <div className="col">
            <CategoryMap />
          </div>
        </div>
        {/* <div className="row mt-1">
          <div className="col">ddd{category_selected}</div>
        </div> */}
        <div className="row mt-1 ">
          <div className="col">
            <div className="container m-0 p-0">
              <div className="row row-cols-1 row-cols-md-3 g-2 p-0 m-0">
                <RankList />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </Fragment>
  );
};

export default Ranks;
